import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
const ModifyModal = ({ip}) => {
  const { id } = useParams('');
  const [selectedModal, setSelectedModal] = useState();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [price,setPrice] = useState('');
  const [sqFeet, setSqFeet] = useState(0);
  const [blacklines, setBlacklines] = useState('');
  const [options, setOptions] = useState('');
  const navigate = useNavigate();
  
  const onSave = () => {
    const updatedModal = {
      name: name,
      image: image,
      price: price,
      sqFeet: sqFeet,
      blacklines: blacklines,
      options: options,
      ip
    }
    axiosInstance
    .post(`/modal/${id}`, updatedModal)
    .then((res) => {
      navigate("/changePrice")
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const optionDescChange = (value, index) => {
    let newOption = options;
    newOption[index] = {...options[index], desc: value}
    setOptions(newOption);
  }

  const optionLevelChange = (value, index) => {
    let newOption = options;
    newOption[index] = {...options[index], level: value}
    setOptions(newOption);
  }

  const optionCostChange = (value, index) => {
    let newOption = options;
    newOption[index] = {...options[index], cost: value}
    setOptions(newOption);
  }
  useEffect(() => {
    axiosInstance
      .get(`/modal/${id}`)
      .then((response) => {
        setSelectedModal(response.data)
        setName(response.data.name)
        setPrice(response.data.price)
        setSqFeet(response.data.sqFeet)
        setBlacklines(response.data.blacklines)
        setOptions(response.data.options)
        setImage(response.data.image)
        setLoading(false);
      }).catch((err) => {
        console.log(err);
      })
  }, [])

  return (
    <div >
      {!loading && <>
        <h1 className='text-center text-3xl'>Modal Editing</h1>
        <div className='w-3/5 sm:w-2/5 mb-10 m-auto flex flex-col my-5'>

          <div className='w-fit mx-auto'>

            <div className='flex justify-end'>
              <label htmlFor="modalName" className='mr-1'>Modal Name:</label>
              <input type="text" id='modalName' className='h-5 border border-black rounded'  value={name} onChange={e => setName(e.target.value)}/>
            </div>

            <div className='flex justify-end'>
              <label htmlFor="price" className='mr-1'>Price:</label>
              <input type="number" id='price' className='h-5 border border-black rounded'  value={price} onChange={e => setPrice(e.target.value)}/>
            </div>

            <div className='flex justify-end'>
              <label htmlFor="sqft" className='mr-1'>Sqare Footage:</label>
              <input type="number" id='sqft' className='h-5 border border-black rounded '  value={sqFeet} onChange={e => setSqFeet(e.target.value)}/>
            </div>

          </div>
          <div className='w-full my-5'>
            <h2 className='text-center text-xl'>Options</h2>
            
            {options.map((option, index) => (
              <div key={index} className='my-5'>
                <h3 className='text-lg '>Option {index + 1}</h3>
                <div className='flex flex-col justify-center'>

                  <div className='w-full flex justify-start'>
                    <label htmlFor={"desc_" + index} className='w-1/3 mr-1'>Description:</label>
                    <input type="text" id={"desc_" + index} className='w-2/3 h-5 border border-black rounded min-w-[285px]' value={option.desc} onChange={e => optionDescChange(e.target.value, index)}/>
                  </div>

                  <div className='w-full flex justify-start'>
                    <label htmlFor={"level_" + index} className='w-1/3 mr-1'>Floor Level:</label>
                    {/* <input type="text" id={"level_" + index} className='h-5 border border-black rounded ' defaultValue={option.level} /> */}
                    <select name="" id={"level_" + index} className='h-5 border border-black rounded' value={option.level} onChange={e => optionLevelChange(e.target.value, index)}>
                      <option value="Exterior">Exterior</option>
                      <option value="Main">Main</option>
                      <option value="Upper">Upper</option>
                    </select>
                  </div>

                  <div className='w-full flex justify-start'>
                    <label htmlFor={"level_" + index} className='w-1/3 mr-1'>Cost:</label>
                    <input type="text" id={"level_" + index} className='h-5 border border-black rounded ' value={option.cost} onChange={e => optionCostChange(e.target.value, index)}/>
                  </div>
                </div>

              </div>
            ))}
          </div>

        </div>
            <div id='button-wrapper' className='w-3/5 mx-auto flex justify-evenly'>
              <button className='w-20 h-8 bg-v-green text-white rounded text-lg' onClick={onSave}>Save</button>
              {/* <button className='w-20 h-8 bg-red-900 text-white rounded text-lg'>Delete</button> */}
            </div>
      </>}
    </div>

  )
}

export default ModifyModal