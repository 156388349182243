import React, { useState } from 'react'
import axiosInstance from '../axiosInstance';
import Summary from './Summary';
import { renderToStaticMarkup } from 'react-dom/server';
const ContactInfo = ({ close, allOptions = {}, data = {}, lot = {} }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmial] = useState("");
    const [phone, setPhone] = useState("");
    const [q1Answer, setQ1Answer] = useState("");
    const [q2Answer, setQ2Answer] = useState("");
    const [q3Answer, setQ3Answer] = useState("");
    const [emailSent, setEmailSent] = useState("");
    const [formErr, setFormErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [processing, setProcessling] = useState(false);

    const user = {
        firstName,
        lastName,
        email,
        phone,
        q1Answer,
        q2Answer,
        q3Answer
    };
    const validPhone = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/);
    const validEmail = new RegExp(/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,6}$/);
    const submit = () => {
        setProcessling(true)
        if (firstName === "" || lastName === "" || email === "" || phone === "") {
            setFormErr(true);
        } else {
            if (!validPhone.test(phone) || !validEmail.test(email)) {
                setPhoneErr(true);
                setEmailErr(true);
                return;
            }
            updateHTML();
        }
    }


    const resetForm = () => {
        setFormErr(false);
        setPhoneErr(null);
        setEmailErr(null);
    }
    const updateHTML = () => {
        try {
            sendMail(renderToStaticMarkup(<Summary modal={data} state={allOptions} user={user} lot={lot}/>));
        } catch (error) {
            console.log(error)
        }
            
        
    }

    const sendMail = (html) => {

        const newBuild = {
            user: user,
            allOptions,
            html,
        };
        // console.log(JSON.stringify(newBuild))
        axiosInstance.post("/mail", newBuild)
            .then((response) => {
                setEmailSent(response.data)
            }).catch((error) => {
                console.log(error)
            });
    };

    return (
        <div className='w-full h-full fixed top-0 left-0 z-[999] flex bg-gray-600 bg-opacity-60 backdrop-blur-md'>
            <div className='w-11/12 h-fit bg-slate-100 m-auto rounded-md text-lg pb-5 md:w-3/4 lg:w-2/3 xl:w-1/2 '>
                <div className='w-8 h-8 bg-white ml-auto mr-4 mt-4 font-bold text-center hover:cursor-pointer rounded border border-gray-300 flex justify-center' onClick={close} >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        {/* <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                </div>
                {emailSent === true ? (
                    <div id='success-wrapper' className='p-4'>
                        <div className='text-center'>
                            <h2 className='text-4xl font-bold'>Success!</h2>
                            <p className='text-3xl font-semibold'>Check your inbox for a copy of your summary.</p>
                            <p className='text-xl font-semibold'>It may take a few minutes to show up in your inbox.</p>
                            <p className='text-xl font-semibold'>Ensure to also check your spam folder.</p>
                            <a href="https://www.verandalegacy.ca/" ><button className='bg-v-green text-white py-2 px-4 rounded-sm mt-5'>Return to Veranda Home</button></a>
                        </div>
                    </div>
                ) : (
                    
                    <div id="contact-form-wrapper">
                    {processing === true ? (<div>
                        <div id='success-wrapper' className='p-4'>
                        <div className='text-center'>
                            <h2 className='text-4xl font-bold'>Processing your request!</h2>
                            <p className='text-xl font-semibold'>It may take a minute to finalize</p>
                        </div>
                    </div>
                    </div>) : (<div>
                        <h1 className='text-3xl font-semibold text-center'>Congratulations on designing your home!</h1>
                        <h3 className='text-center'>Please enter your information to recieve a copy of your build.</h3>
                        {formErr && <h1 className='text-red-600 font-extrabold text-center'>Please Fill Out All Fields.</h1>}
                        <div className='w-2/3 grid grid-cols-2 m-auto gap-2' onClick={resetForm}>
                            <div className='flex flex-col w-5/6'>
                                <label htmlFor="FirstName">First Name</label>
                                <input id="FirstName" type="text" className='border rounded-sm border-gray-300 pl-1 font-sans' required value={firstName} onChange={e => setFirstName(e.target.value)} />
                            </div>
                            <div className='flex flex-col w-5/6'>
                                <label htmlFor="LastName">Last Name</label>
                                <input id="LastName" type="text" className='border rounded-sm border-gray-300 pl-1 font-sans' required value={lastName} onChange={e => setLastName(e.target.value)} />
                            </div>
                            <div className='flex flex-col w-5/6'>
                                <label htmlFor="Email" className='flex'>Email {emailErr && <p className='text-red-600 ml-6'>Invalid Email</p>}</label>
                                <input id="Email" type="email" className='border rounded-sm border-gray-300 pl-1 font-sans' required value={email} onChange={e => setEmial(e.target.value)} />
                            </div>
                            <div className='flex flex-col w-5/6'>
                                <label htmlFor="Phone" className='flex'>Phone {phoneErr && <p className='text-red-600 ml-6'>Invalid Phone</p>}</label>
                                <input id="Phone" type="tel" className='border rounded-sm border-gray-300 pl-1 font-sans' pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}' required value={phone} onChange={e => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div id='questions-wrapper' className='w-2/3 m-auto'>
                            <div id='q1-wrapper' className='flex flex-col'>
                                <label htmlFor="q1-text">Are you currently living in Canada or Calgary?</label>
                                <textarea name="q1-answer" id="q1-text" rows={2} cols={6} className='border rounded-sm border-gray-300 resize-none pl-1' value={q1Answer} onChange={e => setQ1Answer(e.target.value)}></textarea>
                            </div>
                            <div id='q2-wrapper' className='flex flex-col'>
                                <label htmlFor="q2-text">Is there a specific area or neighborhood you are interested in building in?</label>
                                <textarea name="q2-answer" id="q1-text" rows={2} cols={6} className='border rounded-sm border-gray-300 resize-none pl-1' value={q2Answer} onChange={e => setQ2Answer(e.target.value)}></textarea>
                            </div>
                            {/* <div id='q3-wrapper' className='flex flex-col'>
                                <label htmlFor="q3-text">(PLACE HOLDER FOR QUESTION 3)</label>
                                <textarea name="q3-answer" id="q1-text" rows={2} cols={6} className='border rounded-sm border-gray-300 resize-none pl-1' value={q3Answer} onChange={e => setQ3Answer(e.target.value)}></textarea>
                            </div> */}
                        </div>
                        <div id="button-wrapper" className='w-full flex justify-center mt-5'><button className='bg-v-green px-3 text-xl rounded-sm flex align-middle text-white' onClick={submit}>Continue</button></div>
                    </div>)}

                        
                    </div>
                )}
            </div>
            <div className='bg-white font-mono'>
                {/* <Summary modal={data} state={allOptions} user={user} lot={lot}/> */}
            </div>

        </div>
    )
}

export default ContactInfo