import { Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import Start from './pages/Start';
import Lots from './pages/Lots';
import Modals from './pages/Modals';
import Options from './pages/Options';
import AdminLogin from './pages/AdminLogin';
import PriceChange from './pages/PriceChange';
import Footer from './components/Footer';
import ModifyModal from './pages/ModifyModal';
// import ContactInfo from './pages/ContactInfo';
import { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';

function App() {
  const [modalSelection, setModalSelection] = useState({});
  const [onstart, setOnstart] = useState(true);
  const locate = useLocation().pathname;
  const [clientip, setClientIp] = useState()
  const [ ipAccept, setIpAccept ] = useState(false)
  const [selectedLot, setSelectedLot] = useState();
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        setClientIp(data.ip)
      })
      .catch(error => {
        console.error('Error fetching IP:', error);
      });
  }, [])
  
  const ip = {
    clientip
  };

  useEffect(() => {
    // if(clientip !== undefined)
    axiosInstance.post("/verify", ip)
        .then((res) => {
          if(res.data.connect){
            setIpAccept(true);
          }
        })
        .catch((err) => {
          setIpAccept(false);
          console.log(err)
        })
  },[clientip])

  useEffect(() => {
    if (locate === "/") {
      setOnstart(true)
    } else {
      setOnstart(false)
    }
  }, [locate])

  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      navigate("/");
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };


  return (
    <div className='min-w-[500px] w-full font-garamond'>
      <Header onstart={onstart} />
      <main className='min-w-fit w-full min-h-[80vh]'>
        <Routes>
          <Route path='/*' element={<Start />} />
          <Route path='/lots' element={<Lots setLot={setSelectedLot} selectedLot={selectedLot}/>} />
          <Route path='/modals' element={<Modals setModalSelection={setModalSelection} selectedLot={selectedLot}/>} />
          <Route path='/options' element={<Options data={modalSelection} lot={selectedLot} />} />

          {ipAccept && <Route path='/changePrice' element={<PriceChange />} />}
          {ipAccept && <Route path='/ModifyModal/:id' element={<ModifyModal ip={clientip}/>} />}
          {/* <Route path='/AdminLogin' element={<AdminLogin setToken={setToken} />} /> */}
          {/* <Route path='/contact' element={<ContactInfo/>} /> */}
        </Routes>
      </main>
      {/* <Link to={"/changePrice"}>
        <div>Edit Modals</div>
      </Link> */}
        <Footer />
    </div>
  );
}

export default App;
