import React, { useEffect, useState } from 'react'
import axiosInstance from '../axiosInstance'
import { Link } from 'react-router-dom';

const PriceChange = () => {
    const [modals, setModals] = useState();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axiosInstance.get('/modal')
            .then((res) => {
                setModals(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <div>
            {loading ? (
                <></>
            ) : (
                <div className="w-4/5 m-auto grid grid-cols-3 gap-10 lg:w-3/5 lg:gap-16 sm:grid-cols-3 mt-10 ">
                    {modals.map((modal, index) => (
                        <Link to={`../ModifyModal/${modal._id}`} key={index} className='col-span-1 last:col-start-2'>
                            <div className="w-full hover:cursor-pointer h-1/3 col-span-1 " key={modal._id} >
                                <img src={modal.image} alt={modal.name} />
                                <div className="w-full h-10 flex items-center justify-center bg-v-green text-white">{modal.name}</div>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}
export default PriceChange;