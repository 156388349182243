import { useNavigate } from "react-router-dom";
const Header = ({onstart}) => {
    const navigate = useNavigate()
    const back = () => {
        navigate(-1);
    }
    return (
        <div className="px-8 pt-8 h-[176px]">
            <div className="flex-row  justify-between items-center w-max">
                <a href="https://www.verandalegacy.ca/" target="_blank" rel="noreferrer" className="w-max block"><img src={"../Veranda-at-Legacy_logo_green_rgb.png"} alt="Veranda Logo" className="md:max-h-30 max-h-24" /></a>
                {!onstart &&
                <div id="back-button" className="flex items-center justify-between w-5/12 md:ml-auto mt-10 text-gray-900 hover:cursor-pointer" onClick={back}>
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke={"currentColor"} height={24} width={24} strokeWidth={"2"} strokeLinejoin="round" strokeLinecap="round">
                            {/* <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
                            <line x1={19} x2={7} y1={12} y2={12}></line>
                            <polyline points="12 19 5 12 12 5"></polyline>
                            {/* <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /> */}
                        </svg>
                    </div>
                    <span className="ml-1 font-semibold hidden sm:block leading-[26px]">Back</span>
                </div>
                }
            </div>
        </div>
    )
}
export default Header;