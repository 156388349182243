export const lots = [
    {
        section: 45,
        unit: 42,
        dimention: "44' x 121'",
        comp_modals: ["Briarwood 34", "Alder", "Willow", "Elm", "Cypress","Cedar","Briarwood 36","Juniper"]
    },
    {
       section: 45,
        unit: 21,
        dimention: "42' x 124'",
        comp_modals: ["Briarwood 34", "Alder", "Willow"]
    },
    {
       section: 45,
        unit: 22,
        dimention: "42' x 123'",
        comp_modals: ["Briarwood 34", "Alder", "Willow"]
    },
    {
       section: 75,
        unit: 22,
        dimention: "52' x 123'",
        comp_modals: ["Sequoia", "Sequoia II","Birch","Evergreen","Redwood"]
    },
    {
       section: 75,
        unit: 13,
        dimention: "52' x 128'",
        comp_modals: ["Sequoia", "Sequoia II","Birch","Evergreen","Redwood"]
    },
]