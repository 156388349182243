import { useEffect, useState } from "react";
import OptionSelect from "../components/optionSelect";
import { useNavigate } from "react-router-dom";
import TotalPrice from "../components/TotalPrice";
import ContactInfo from "./ContactInfo";

const Options = ({ data, lot }) => {
    const [displayedImg, setDisplayedImg] = useState()
    const [addCost, setaddCost] = useState(0);
    const [imgFullScreen, setImageFullScreen] = useState({ display: 'none' })
    const [contactDisplay, setContactDisplay] = useState({ display: "none" });
    const [floor1Options, setFloor1Options] = useState([]);
    const [floor2Options, setFloor2Options] = useState([]);
    const [exteriorOption, setExteriorOption] = useState([]);
    const [custom, setCustom] = useState("");
    const [floor1, setFloor1] = useState("");
    const [floor2, setFloor2] = useState("");
    const [floor1level, setFloor1level] = useState({});
    const [floor2level, setFloor2level] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const allOptions = {
        floor1: floor1Options,
        floor2: floor2Options,
        exterior: exteriorOption,
        addCost: addCost,
        custom: custom
    }

    useEffect(() => {
        if (data.blacklines !== undefined) {
            setLoading(false);
            setFloor1level(data.blacklines.floor1)
            setFloor2level(data.blacklines.floor2)
            setFloor1(data.blacklines.floor1.level)
            setFloor2(data.blacklines.floor2.level)
            setDisplayedImg(data.blacklines.floor1.image)
        }
        if (!data.blacklines) {
            navigate("/");
            window.scrollTo(0,0);
        }

    }, [data.blacklines, navigate])

    const show = () => {
        setImageFullScreen({ display: 'block', backgroundImage: 'url(' + displayedImg + ')', position: 'fixed' });
    }
    const hide = () => {
        setImageFullScreen({ display: 'none' });
    }

    const showContact = () => {
        setContactDisplay({ display: "block" })
    }
    const closeContact = () => {
        setContactDisplay({ display: "none" })
    }
    const hasExterior = () => {
        for (let i = 0; i < data.options.length; i++) {
            if (data.options[i].level === "Exterior") {
                return true
            }
        }
        return false
    }


    return (
        <div id="option-wrapper">
            {loading === true ? (
                <div></div>
            ) : (
                <>
                    <TotalPrice modelPrice={data.price} optionsCost={addCost} />
                    <div className="w-auto flex justify-center">
                        <div className="relative min-w-80 w-[45%] h-fit " >
                            <img src={data.image} alt="Willow" width={"100%"} className="min-w-80" />
                            <div className="w-fit bg-v-green text-xl h-10 px-10 text-white flex items-center absolute bottom-2 left-2">{data.name}</div>
                            <div className="w-fit bg-v-green text-xl h-10 px-10 text-white flex items-center absolute top-2 right-2">{data.sqFeet} sq. Feet</div>
                        </div>
                    </div>

                    <section id="floorPlan" className="xl:mx-auto mt-10 xl:w-11/12 w-full 2xl:w-[1500px]">
                        <div id="floorplan-title" className="w-2/3 m-auto">
                            <h1 className="text-2xl font-semibold">Customization Options</h1>
                            <p>Fully customize your new home</p>
                        </div>
                        <div className="flex justify-evenly gap-3 xl:w-2/3 mx-auto pt-3 lg:w-10/12 w-full md:flex-row flex-col">
                            <div id="dropdown-wrapper" className="w-[415px] md:ml-4 mx-auto">
                                <OptionSelect data={data} level={floor1level} floor={floor1 + " Floor"} setImg={setDisplayedImg} setaddCost={setaddCost} selectedOptions={floor1Options} setSelectedOptions={setFloor1Options} />
                                {data.blacklines.floor2.name !== "" &&
                                    <OptionSelect data={data} level={floor2level} floor={floor2 + " Floor"} setImg={setDisplayedImg} setaddCost={setaddCost} selectedOptions={floor2Options} setSelectedOptions={setFloor2Options} />
                                }
                                {hasExterior() === true &&
                                    <OptionSelect data={data} level={"Exterior"} floor={"Exterior"} setImg={setDisplayedImg} setaddCost={setaddCost} selectedOptions={exteriorOption} setSelectedOptions={setExteriorOption} />
                                }
                                <div>
                                    <h4>Custom</h4>
                                    <textarea name="" id="" className="border border-gray-400 p-2 w-full" rows={5} value={custom} maxLength={600} onChange={(e) => setCustom(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="border border-gray-400 rounded md:w-1/2 w-[415px] h-[550px] flex items-center hover:cursor-zoom-in md:mr-4 m-auto" onClick={show}>
                                <img src={displayedImg} alt="Img" className="m-auto h-[540px] w-10/12 object-contain" id="displayedImg" />
                            </div>
                        </div>
                    </section>
                    <div className="w-32 h-10 bg-v-cream flex justify-center items-center font-semibold fixed right-10 bottom-10 z-50 hover:cursor-pointer" onClick={showContact}>Get Summary</div>
                    <div style={contactDisplay}>
                        <ContactInfo close={closeContact} allOptions={allOptions} data={data} lot={lot} />
                    </div>
                    <div id="fullpage" onClick={hide} className="hidden absolute z-[999] top-0 left-0 w-full h-full bg-contain bg-no-repeat bg-center bg-black bg-opacity-65 hover:cursor-zoom-out" style={imgFullScreen}></div>
                </>
            )}
        </div>
    )
}
export default Options;